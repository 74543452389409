import React from "react";
import { Helmet } from "react-helmet";
import { Heading, Content } from "../styles";

const GoldenVisa = () => {
  return (
    <div>
      <Helmet>
        <title>
          Moving to a Golden Visa from a Freelancer visa as a Software Engineer.
          A step - by - step guide.
        </title>
        <meta
          name="description"
          content="Step by step guide to get yourself a UAE Golden VISA as a Software Engineer."
        />
      </Helmet>
      <Heading>
        Moving to a Golden Visa from a Freelancer visa as a Software Engineer. A
        step - by - step guide.
      </Heading>

      <Content>
        <p>
          <strong>
            Published on: <span>26th June, 2022</span>
          </strong>
        </p>
        <section>
          <p>Hey there!</p>
          <p>
            I recently managed to change my Freelancer Visa to a Golden Visa in
            the category{" "}
            <b>"Persons who are talented - Software Engineering".</b>
          </p>
          <p>
            Thought I would do a write up in case anyone is in a similar
            situation and would be of help, since the information is all over
            the place.
          </p>

          <p>
            Again, this is strictly for my personal case, and I don't know how
            other categories would work.
          </p>
          <h4>Timeline</h4>
          <p>
            It took around a week or less. Major props to the government for
            making this hassle free.
          </p>

          <p>
            I had to go through 5 services and I applied through Abu Dhabi,
            which means I have an Abu Dhabi visa. No reason for this really, I
            think I clicked the first option I saw under the Federal Authorities
            and it was AD. For Dubai, I think you can also get the process
            started through Amer centers, for any other emirate, Amer won't be
            able to help.
          </p>
        </section>
        <br />
        <section>
          <h4>The steps were as follows:</h4>
          <div style={{ overflowX: "auto" }}>
            <table className="centered highlight steps">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Service Name</th>
                  <th>Reason</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    VISA - GOLDEN RESIDENCE - NOMINATION REQUEST FOR GOLDEN
                    RESIDENCE - NEW REQUEST
                  </td>
                  <td>
                    To apply for a golden visa, someone has to nominate you.
                    Usually, this is the company that you work for, since I was
                    on a Freelancer license, I applied myself. I had to put it
                    an <code>Establishment Number</code> which I just put as my
                    Freelancer License Number
                  </td>
                  <td>52.50 AED</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    VISA - GOLDEN RESIDENCE - PERSONS WHO ARE TALENTED - ISSUE
                    NEW VISA
                  </td>
                  <td>
                    Once your nomination request is successful, then you can go
                    on to issue a new VISA for yourself. This should also be
                    approved, as the request was successful. You will have to
                    cancel your old visa, otherwise this won&#39;t be approved.
                    Once approve, you will get an Entry Permit
                  </td>
                  <td>305.52 AED</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    RESIDENCY - ALL RESIDENCE TYPES - CHANGE STATUS - CHANGE
                    STATUS
                  </td>
                  <td>
                    Next, you have to apply for the Change Status service.
                  </td>
                  <td>558.93 AED</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    RESIDENCY - GOLDEN RESIDENCY (10 YEARS) - PERSONS WHO ARE
                    TALENTED - ISSUE RESIDENCE
                  </td>
                  <td>
                    Once you are approved for Changing your status, you can then
                    apply for the Residency Permit. You will also have to get a
                    Medical Test done at one of the MOHRE / DHA health centers.
                  </td>
                  <td>AED 1,217.80 + AED 345 (medical)</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    EMIRATES ID SERVICES-EMIRATES ID FOR RESIDENT-EMIRATES ID
                    FOR RESIDENT-RENEW EMIRATES ID
                  </td>
                  <td>
                    Once your residency is approved, you will then have to apply
                    for the Renewal of your EID, since you already had one
                    before (assuming you are a UAE resident).
                  </td>
                  <td>AED 1054.20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <br />
        <section>
          <p>
            The documents that I submitted for the various services in no
            particular order:
          </p>
          <ul className="document-list">
            <li>Old EID Front / Back</li>
            <li>
              Attested Degree (I graduated from a UAE university and got it
              attested from Ministry of Education)
            </li>
            <li>Letter from Employer stating your position</li>
            <li>Previous month's bank statement</li>
            <li>Father's Passport copy</li>
            <li>Tenancy Contract</li>
            <li>Passport Photo</li>
            <li>Medical Certificate</li>
          </ul>
        </section>
        <section>
          <span role="img" aria-label="Confetti Emoji">
            Once your EID request is approved, then you should find the VISA and
            EID in your ICP Application!
          </span>
        </section>
      </Content>
    </div>
  );
};

export default GoldenVisa;
