import React from "react";
import AboutMe from "../AboutMe/component.jsx";
import Blog from "../Blog/component.jsx";
import Projects from "../Projects/component.jsx";
import posts from "../Blog/constants/posts";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link, TopNav } from "./styles.js";
import GoldenVisa from "../Blog/GoldenVisa/component.jsx";

const IonicWithJSS = React.lazy(() =>
  import("../Blog/IonicWithJSS/component.jsx")
);
const FirstPost = React.lazy(() => import("../Blog/FirstPost/component.jsx"));
const InstallPopOS = React.lazy(() =>
  import("../Blog/InstallPopOSOnDell/component.jsx")
);
const InstaBot = React.lazy(() => import("../Blog/InstagramBot/component.jsx"));

const Home = (props) => {
  return (
    <div>
      <Router>
        <TopNav>
          <Link to="/">Amin Limbada</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/projects">Places I have worked</Link>
        </TopNav>
        <div className="content-wrapper">
          <Routes>
            <Route exact path="/" element={<AboutMe />} />

            <Route exact path="/projects" element={<Projects />} />

            <Route exact path="/blog" element={<Blog posts={posts} />} />

            <Route
              exact
              path={`blog/${posts[0].url}`}
              element={<FirstPost />}
            />
            <Route
              exact
              path={`blog/${posts[1].url}`}
              element={<InstallPopOS />}
            />
            <Route
              exact
              path={`blog/${posts[2].url}`}
              element={<IonicWithJSS />}
            />
            <Route exact path={`blog/${posts[3].url}`} element={<InstaBot />} />
            <Route
              exact
              path={`blog/${posts[4].url}`}
              element={<GoldenVisa />}
            />

            <Route path="*" element={<AboutMe />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

Home.propTypes = {};

export default Home;
