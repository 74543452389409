import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, Post, Subtitle } from "./styles";

const Blog = (props) => {
  const { posts } = props;
  return (
    <Wrapper>
      <div>
        {posts
          .slice(0)
          .reverse()
          .map((post) => (
            <Link to={`/blog/${post.url}`} key={post.url}>
              <Post>{post.title}</Post>
              <Subtitle>{post.description}</Subtitle>
              <br />
            </Link>
          ))}
      </div>
    </Wrapper>
  );
};

export default Blog;
