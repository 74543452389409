const posts = [
  {
    ID: 1,
    title: "Why did I decide to do this?",
    description: "My first blog post, mainly consists of ramblings.",
    url: "why-did-I-decide-to-do-this",
  },
  {
    ID: 2,
    title: "Install Pop_OS! on a Dell XPS 15 9570",
    description: "Coherent steps for running Pop_OS! on your Dell XPS 15 9570",
    url: "how-to-install-pop-os19-10-on-dell-xps-15-9570",
  },
  {
    ID: 3,
    title: "How to use CSS-in-JS with Ionic React",
    description:
      "Detailed explanation to set up JSS with Ionic React, setting their global properties and workarounds to change root styles of Ionic components",
    url: "how-to-use-css-in-js-with-ionic-react",
  },
  {
    ID: 4,
    title: "How to build a (very badly written) Instagram Bot",
    description:
      "A pretty well written step by step guide for a pretty badly written Instagram bot that pulls content from Reddit and publishes to Instagram",
    url: "how-to-write-an-instagram-bot",
  },
  {
    ID: 5,
    title:
      "Moving to a Golden Visa from a Freelancer visa as a Software Engineer. A step - by - step guide.",
    description:
      "Moving to a Golden Visa from a Freelancer visa as a Software Engineer. A step - by - step guide.",
    url: "moving-to-a-golden-visa-as-a-software-engineer-uae",
  },
];

export default posts;
