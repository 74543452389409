import styled from "styled-components";

export const AboutWrapper = styled.div`
  padding: 24px;
  padding-top: 50px;
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 10px;
  }
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconHolder = styled.div`
  display: flex;
  justify-content: space-evenly;
  column-gap: 50px;
`;

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
`;

export const GreetingContainer = styled.section`
  text-align: center;
`;
export const SocialImage = styled.img`
  @media (max-width: 425px) {
    width: 30px;
  }
`;
