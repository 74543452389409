import styled from "styled-components";
import { Link as CustomLink } from "react-router-dom";
export const TopNav = styled.div`
  background-color: #333;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 10px;
  overflow-x: hidden;
  padding-top: 30px;
  transition: 0.5s;
  box-shadow: 35px 25px 37px -11px rgba(0, 0, 0, 0.24);
`;

export const Link = styled(CustomLink)`
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  flex: 1;

  &:hover {
    background-color: #ddd;
    color: black;
  }

  &:active {
    color: white;
  }

  @media screen and (max-width: 600px) {
    padding: 12px;
  }
`;
