import React from "react";

import Me from "../../static/Me.png";
import github from "../../static/github.svg";
import linkedIN from "../../static/linkedin.svg";
import twitter from "../../static/twitter.svg";
import stackoverflow from "../../static/stackoverflow.svg";

import {
  GreetingContainer,
  AboutWrapper,
  SocialLinksWrapper,
  IconHolder,
  ProfileImage,
  SocialImage,
} from "./styles";

const About = (props) => {
  return (
    <div>
      <AboutWrapper>
        <SocialLinksWrapper>
          <ProfileImage src={Me} width="100" height="100" alt="Me" />
          <IconHolder>
            <a href="https://github.com/loxator">
              <SocialImage
                width="50"
                height="100"
                src={github}
                alt="Github Profile"
              />
            </a>
            <a href="https://www.linkedin.com/in/amin-limbada/">
              <SocialImage
                width="50"
                height="100"
                src={linkedIN}
                alt="LinkedIN Profile"
              />
            </a>
            <a href="https://twitter.com/aminlimbada/">
              <SocialImage
                width="50"
                height="100"
                src={twitter}
                alt="Twitter Profile"
              />
            </a>{" "}
            <a href="https://stackoverflow.com/users/1368671/loxator">
              <SocialImage
                width="50"
                height="100"
                src={stackoverflow}
                alt="StackOverflow Profile"
              />
            </a>
          </IconHolder>
        </SocialLinksWrapper>
        <GreetingContainer>
          <article>
            <h1>Hello There !</h1>
            <p>
              First of all, thanks for stumbling upon my website and checking it
              out!
            </p>
            <p>
              I am a Software Engineer who loves simplicity and minimalistic
              product design, because I believe that simplicity is the core to
              making any program succeed.
            </p>
            <p>
              I find myself working on various things from time to time. My day
              to day work is mainly in front-end web development. Specifically
              React / Redux / HTML &#38; CSS. Also dabble in DevOps &#38;
              Backend from time to time.
            </p>
            You can reach me on&nbsp;
            <a href="mailto:aminlimbada94@gmail.com">aminlimbada94@gmail.com</a>
            <br />
          </article>
        </GreetingContainer>
      </AboutWrapper>
    </div>
  );
};

About.propTypes = {};

export default About;
