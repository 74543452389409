import React, { Suspense } from "react";
import TopNav from "./components/TopNav/component.jsx";
import "materialize-css/dist/css/materialize.min.css";
import { Circles } from "react-loader-spinner";
import "./App.css";
import { Helmet } from "react-helmet";


const TITLE = "Amin Limbada";


function App() {
  return (
    <div className="App">
      <Helmet>
        <title>{TITLE}</title>
        <link
          rel="icon"
          type="image/png"
          href="./static/favicon-32x32.png"
          sizes="16x16"
        />
      </Helmet>
      <Suspense
        fallback={
          <div className="loader">
            <Circles
              color="#333"
              height={80}
              width={80}
              ariaLabel="loading-indicator"
            />
          </div>
        }
      >
        <TopNav />
      </Suspense>
    </div>
  );
}

export default App;
