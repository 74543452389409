import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
`;

export const Post = styled.h3`
  color: black;
  text-decoration: underline;
`;

export const Subtitle = styled.p`
  color: black;
  text-decoration: none;
`;

export const Content = styled.article`
  max-width: 900px;
  margin: auto;
  padding: 20px;
  font-size: 20px;

  @media only screen and (max-width: 600px) {
    max-width: 300px;
    margin: auto;
    padding: 20px 0;
  }
`;

export const Heading = styled.h3`
  text-align: center;
  text-decoration: underline;

  @media only screen and (max-width: 600px) {
    margin: 30px 10px;
    text-align: center;
    text-decoration: underline;
  }
`;
