import React, { useEffect, useState } from "react";
import "./styles.css";
import consensys from "../../static/consensys.svg";
import consol from "../../static/consol.svg";
import vacation from "../../static/vacation.svg";
import national from "../../static/national.svg";
import xdefi from "../../static/xdefi.svg";

// Import Materialize
import M from "materialize-css";
import { Helmet } from "react-helmet";
const Projects = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems);
  }, []);
  const [doOrDid, setdoOrDid] = useState("do");
  return (
    <div className="project-wrapper">
      <Helmet>
        <title>Personal Projects &amp; Work</title>
        <meta name="description" content="My CV" />
      </Helmet>
      <h1>Things I {doOrDid} in ...</h1>
      <div>
        <div>
          <ul className="collapsible drawer">
            <li>
              <div
                className="row collapsible-header expandible"
                onClick={() => setdoOrDid("do")}
              >
                <img src={xdefi} alt="" className=" company-image col l4" />
                &nbsp;
                <h5 className="col l4">XDEFI Wallet</h5>
                <i className="material-icons col l4">arrow_drop_down</i>
              </div>
              <div className="collapsible-body">
                <div className="list">
                  <ul className="collection no-border">
                    <li className="collection-item">
                      Making XDEFI wallet more functional for D-Apps by
                      implementing more Ethereum Improvement Proposals (EIPs)
                    </li>
                    <li className="collection-item">
                      Added support in XDEFI Wallet for Partially Signed Bitcoin
                      Transaction (PSBTs) for necessary UTXO networks
                    </li>
                    <li className="collection-item">
                      Decoding common raw transaction HEX from Smart Contracts
                      (UniswapV2, Token Approval) to present to the end user in
                      a readable manner
                    </li>
                    <li className="collection-item">
                      Translating Figma designs to Web-3 based React components
                    </li>
                    <li className="collection-item">
                      Configure Storybook for faster and better UI development
                    </li>
                    <li className="collection-item">
                      Write unit tests using React Testing Library and
                      Jest-styled-components
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li>
              <div
                className="row collapsible-header expandible"
                onClick={() => setdoOrDid("did")}
              >
                <img src={national} alt="" className=" company-image col l4" />
                &nbsp;
                <h5 className="col l4">The National</h5>
                <i className="material-icons col l4">arrow_drop_down</i>
              </div>
              <div className="collapsible-body">
                <div className="list">
                  <ul className="collection no-border">
                    <li className="collection-item">
                      Maitaining and adding features to{" "}
                      <a href="http://thenationalnews.com">The National's</a>{" "}
                      website.
                    </li>
                    <li className="collection-item">
                      Understanding requirements from Editors and other product
                      owners and translating them to real world features by
                      building them on top of a SSR React Product
                    </li>
                    <li className="collection-item">
                      Mocking up designs on Figma
                    </li>
                    <li className="collection-item">
                      Writing Unit and End to End tests using Jest and Enzyme.
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div
                className="row collapsible-header expandible"
                onClick={() => setdoOrDid("did")}
              >
                <img src={consensys} alt="" className=" company-image col l4" />
                &nbsp;
                <h5 className="col l4">ConsenSys MENA</h5>
                <i className="material-icons col l4">arrow_drop_down</i>
              </div>
              <div className="collapsible-body">
                <div className="list">
                  <ul className="collection no-border">
                    <li className="collection-item">
                      Converting Wireframes to HTML components for production
                      ready web applications
                    </li>
                    <li className="collection-item">
                      Using ReactJS combined with TDD for bringing the front-end
                      user stories to life
                    </li>
                    <li className="collection-item">
                      Creating and enhancing the performance of Docker images by
                      leveraging knowledge of multistage builds
                    </li>
                    <li className="collection-item">
                      Writing basic Kubernetes manifest for creating front-end
                      pod
                    </li>
                    <li className="collection-item">
                      Configuring Jenkins server for CI/CD purposes on an Ubuntu
                      VM
                    </li>
                    <li className="collection-item">
                      Writing Unit and End to End tests using Jest, Enzyme,
                      Cypress and Puppeteer
                    </li>
                    <li className="collection-item">
                      Organizing stand-up meetings and acting as interim Scrum
                      master
                    </li>
                    <li className="collection-item">
                      Using Terraform to conduct simple infrastructure
                      management
                    </li>
                    <li className="collection-item">
                      &nbsp; Mocking up basic wireframes using Figma
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li>
              <div
                className="row collapsible-header expandible"
                onClick={() => setdoOrDid("did")}
              >
                <img src={consol} alt="" className="company-image col l4" />
                &nbsp;
                <h5 className="col l4">Consol* MENA</h5>
                <i className="material-icons  col l4">arrow_drop_down</i>
              </div>
              <div className="collapsible-body">
                <div className="list">
                  <ul className="collection">
                    <li className="collection-item">
                      Ensured efficient software releases by manually testing
                      new features after development
                    </li>
                    <li className="collection-item">
                      Implemented features by continuously communicating and
                      following up with the clients
                    </li>
                    <li className="collection-item">
                      Analyzed unit tests to find bottlenecks and improve their
                      execution speed by 50%, by decoupling them into smaller
                      test suites instead of one big test suite.
                    </li>
                    <li className="collection-item">
                      Ensured code quality with unit and integration tests by
                      applying Test Driven Development (TDD)
                    </li>
                    <li className="collection-item">
                      Developed in Java, tests which reduced PDF generation
                      errors by 75% by running them after every commit using
                      TestNG and Maven
                    </li>
                    <li className="collection-item">
                      Improved Selenium tests in Java and analyzed test results
                      from Jenkins to provide feedback to clients before every
                      software release
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li>
              <div
                className="row collapsible-header expandible"
                onClick={() => setdoOrDid("do")}
              >
                <img src={vacation} alt="" className="company-image col l4" />
                &nbsp;
                <h5 className="col l4">Free time</h5>
                <i className="material-icons  col l4">arrow_drop_down</i>
              </div>

              <div className="collapsible-body">
                <div className="list">
                  <ul className="collection">
                    <li className="collection-item">
                      Learning and playing songs on my Ukulele
                    </li>
                    <li className="collection-item">
                      Travelling to exotic beachy locations
                    </li>
                    <li className="collection-item">
                      Learning new technologies that pique my interest
                    </li>
                    <li className="collection-item">
                      Reading from time to time and watching Netflix :)
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Projects;
